import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import moment from 'moment';

import Layout from './layout';
import SEO from '../components/seo';

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data; // data.markdownRemark holds our post data
  const { title, date, cover, description } = markdownRemark.frontmatter;
  const { html } = markdownRemark;
  return (
    <Layout>
      <SEO title={title} image={cover.childImageSharp.fixed.src} description={description} />
      <article className="hentry post">
        <header className="hero is-light ">
          <div className="columns is-gapless is-desktop reverse-row-order">
            {cover && (
              <div className="column">
                <Img
                  style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                  sizes={cover.childImageSharp.sizes}
                />
              </div>
            )}
            <div className="column">
              <div className="hero-body">
                <div className="container is-fluid">
                  <h1 className="title is-1 entry-title">{title}</h1>
                  <time datetime={moment(date).format()} className="is-6 is-subtle">{moment(date).fromNow()}</time>
                </div>
              </div>
            </div>
          </div>
        </header>
        <section className="section content">
          <div className="container is-narrow">
            <div
              className="entry-content"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </div>
        </section>
      </article>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        description
        cover {
          childImageSharp {
            sizes(maxWidth: 1000) {
              ...GatsbyImageSharpSizes
            }
            fixed(width: 1200, height: 630) {
							...GatsbyImageSharpFixed
						}
          }
        }
      }
    }
  }
`;
